// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
// import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/custom-post-navigation/index.js'; import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function changeToSvg() {
  $('img[src*=".svg"]').each(function () {
    var $img = $(this),
      imgURL = $img.attr('src'),
      imgClass = $img.attr('class'),
      imgID = $img.attr('id');
    $.get(
      imgURL,
      function (data) {
        // Get the SVG tag, ignore the rest
        var $svg = $(data).find('svg');
        // Add replaced image's ID to the new SVG
        if (typeof imgID !== 'undefined') {
          $svg = $svg.attr('id', imgID);
        }
        if (typeof imgClass !== 'undefined') {
          $svg = $svg.attr('class', imgClass);
        }
        $svg = $svg.removeAttr('xmlns:a');
        $img.replaceWith($svg);
      },
      'xml'
    );
  });
}

function fadeInOneByOne() {
  const $elements = $('.fade-in-one-by-one .elementor-post');
  if ($elements.length === 0) return;
  let timer = 50;
  $elements.each((i, el) => {
    setTimeout(() => {
      $(el).css('opacity', '1');
    }, (timer += 50));
  });
}

function dynamicLogo() {
  const header = document.querySelector('[data-elementor-type="header"]');
  const sections = document.querySelectorAll('section.elementor-section');
  if (!header || !sections) return;

  let areAllSectionsDark = false;
  sections.forEach((section) => {
    if (section.dataset?.darkBackground === 'true') {
      areAllSectionsDark = true;
    } else {
      areAllSectionsDark = false;
      return;
    }
  });
  if (areAllSectionsDark === true) {
    header.setAttribute('data-theme-light', 'true');
    return;
  }

  function checkData(dataAttr) {
    let value = false;
    if (dataAttr) {
      if (dataAttr === 'true') value = true;
      else value = false;
    }
    return value;
  }

  const options = {
    rootMargin: `-175px 0px ${$(window).height() - 175}px 0px`,
    threshold: 0,
  };

  const onIntersect = function (entries) {
    entries.forEach((entry) => {
      const target = entry.target;
      const dataDarkBackground = checkData(target.dataset?.darkBackground);
      const nextElementDataDarkBackground = checkData(
        target.nextElementSibling?.dataset?.darkBackground
      );

      if (entry.isIntersecting && dataDarkBackground) {
        header.setAttribute('data-theme-light', 'true');
      } else if (
        !entry.isIntersecting &&
        dataDarkBackground &&
        !nextElementDataDarkBackground
      ) {
        header.setAttribute('data-theme-light', 'false');
      }
    });
  };

  const observer = new IntersectionObserver(onIntersect, options);
  sections.forEach((section) => {
    observer.observe(section);
  });
}

function calculateOpacity() {
  const scrollTop = $(window).scrollTop();
  const element = $('.lose-opacity-on-scroll');
  let opacity = 1;
  const elementHeight = $(element).height();
  opacity = 1 - ((scrollTop - elementHeight * 0.2) / elementHeight) * 1.2;
  opacity = opacity <= 0 ? 0 : opacity;

  return opacity.toFixed(2);
}

function loseOpacityOnScroll() {
  const opacity = calculateOpacity();
  $('.lose-opacity-on-scroll').css({
    transition: 'unset',
    opacity: opacity,
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  $('.lose-opacity-on-scroll').css({
    transition: 'all 1s ease-in-out',
    opacity: calculateOpacity(),
  });
  dynamicLogo();
  changeToSvg();
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('elementor-active')) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  $('.elementor-menu-toggle ').on('click', function () {
    $('html').toggleClass('fixed-html');
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  fadeInOneByOne();
  // jQuery code goes here
  setTimeout(() => {
    $('.opacity-fix .elementor-motion-effects-layer').css('opacity', '1');
  }, 250);

  changeToSvg();

  let $preloader = $('.preloader');
  setTimeout(function () {
    if ($preloader.length) {
      $preloader.addClass('preloader--hidden');
    }
  }, 1800);
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
let lastScrollTop = 0;
$(window).on('scroll', function () {
  // jQuery code goes here
  // changeToSvg();
  // update();
  let st = $(this).scrollTop();
  if (st > 100) {
    if (st < lastScrollTop) {
      document.body.classList.add('is-scrolling-up');
      document.body.classList.remove('is-scrolling-down');
    } else {
      document.body.classList.remove('is-scrolling-up');
      document.body.classList.add('is-scrolling-down');
    }
  }
  lastScrollTop = st;

  loseOpacityOnScroll();
});
